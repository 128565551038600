<template>
    <div class="order">
        <div>
            <el-tabs type="card" size='mini' style="margin-bottom:10px" v-model="topTab" @tab-click="changeStatuse">
                <el-tab-pane label="全部" name="first"></el-tab-pane>
                <!-- <el-tab-pane label="待收货" name="toReceive"></el-tab-pane>
                <el-tab-pane label="已完成" name="done"></el-tab-pane> -->
                <template v-for="(item,index) in statusList">
                    <el-tab-pane 
                    :label="item.value" 
                    :name="item.id"
                    :key="item.id">
                        <span slot="label">{{item.value}} <span v-if="item.size>0&&item.size">({{item.size}})</span></span>
                    </el-tab-pane>
                </template>
            </el-tabs>
            <el-select @clear="getListPage" clearable v-model="pageInfo.storageId" style="width:150px;margin-right:10px;margin-bottom:5px" placeholder="全部仓库">
                <el-option
                    v-for="item in storageList"
                    :key="item.id"
                    :label="item.value"
                    @click.native="getListPage"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select clearable @clear="clearShop" v-model="shopAndMar" placeholder="请选择店铺" style="width:150px;margin-right:10px">
                <el-option-group
                v-for="group in shopList"
                :key="group.id"
                :label="group.name">
                <el-option
                    @click.native='getShopId(group.id,item.id)'
                    v-for="item in group.marketPlaceTree"
                    :key="item.id"
                    :label="item.name"
                    :value="group.id+'-'+item.id">
                </el-option>
                </el-option-group>
            </el-select>
            <!-- <ShopsAndSites @getShopId='getID' v-if='shopList.length!=0'  style="margin-right:10px" :list="shopList"></ShopsAndSites> -->
            <el-select clearable @clear="getListPage" v-model="pageInfo.checkStatus" style="width:150px;margin-right:10px" placeholder="质检状态">
                <el-option
                    v-for="item in checkStatus"
                    :key="item.id"
                    :label="item.value"
                    @click.native="getListPage"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select clearable @clear="getListPage" v-model="pageInfo.timeType" style="width:150px;margin-right:2px" placeholder="预计到货时间">
                <el-option
                    v-for="item in searchTimeType"
                    :key="item.id"
                    :label="item.value"
                    @click.native="getListPage"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-date-picker
            clearable
            @clear="getPageTime"
            style="margin-right:10px;position:relative;top:1px"
            v-model="time"
            @change="getPageTime"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
            </el-date-picker>
            <el-input clearable @clear="getListPage" v-model="pageInfo.keyword" placeholder="搜索内容" style="width:300px;margin-right:10px">
                <el-select style="width:120px" slot="prepend" v-model="pageInfo.searchType" placeholder="收货单号">
                    <el-option
                        v-for="item in searchTime"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-input>
            <el-button type="primary" @click="find">查询</el-button><br><br>
            <el-button type="primary" @click="addStart('添加收货单')">添加收货单</el-button>
            <el-dropdown placement='bottom' trigger='click'>
                <el-button style="margin-left:10px">批量导入</el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="openBatchUpload('收货')">批量导入收货</el-dropdown-item>
                    <el-dropdown-item @click.native="openBatchUpload('快捷入库')">批量导入快捷入库</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown placement='bottom' trigger='click'>
                <el-button style="margin:-2px 10px 0 10px">批量操作</el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="toBatchQuality('质检')">批量质检</el-dropdown-item>
                    <el-dropdown-item @click.native="toBatchQuality('免检')">批量免检</el-dropdown-item>
                    <el-dropdown-item @click.native='batchRemove'>批量删除</el-dropdown-item>
                    <el-dropdown-item>批量添加采购订单收货</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-button @click.native='batchRemove'>批量删除</el-button>
        </div>
        <div style="flex:1;overflow:auto;padding-top:10px">
            <el-table
                v-loading=loading
                height="100%"
                class="outTable"
                @selection-change="select"
                :span-method="arraySpanMethod"
                :data="tableData"
                
                style="width: 100%;border-top:1px solid #EBEEF5"
                :cell-style="{ fontSize: '13px',}"
                :header-cell-style="{
                    fontSize: '13px',
                }"
                row-key="childId"
                default-expand-all
                :tree-props="{children: 'itemList'}">
                <el-table-column
                    type="selection"
                    width="width">
                </el-table-column>
                <el-table-column
                    prop="imageUrl"
                    label="图片"
                    width="width">
                    <tamplate slot-scope="scope">
                        <div v-if="scope.row.itemList!=undefined" class="tableMenu">
                            <div style="margin-right:30px">
                                <CopyButton :content='scope.row.crNo'>
                                    <el-button slot="copy" type="text" @click="getDetail(scope.row,'detail')">{{scope.row.crNo}}</el-button>
                                </CopyButton>
                            </div>
                            
                            <div class="option" style="width:75%;display:flex;flex-wrap:wrap">
                                <div style="width:17%">
                                    <span>收货类型：</span>
                                    <span>采购订单</span>
                                </div>
                                <div style="width:21.5%">
                                    <span>关联单据号：</span>
                                    <span>{{scope.row.purchaseNo}}</span>
                                </div>
                                <div style="width:17%">
                                    <span>仓库：</span>
                                    <span>{{scope.row.storageName}}</span>
                                </div>
                                <div style="width:17%">
                                    <span>供应商：</span>
                                    <span>{{scope.row.supplierName}}</span>
                                </div>
                                <div style="width:25%">
                                    <span>预计到货时间：</span>
                                    <span>{{scope.row.expectArrivalTime}}</span>
                                </div>
                                <div style="width:17%">
                                    <span>物流单号：</span>
                                    <span>{{scope.row.logisticsNo}}</span>
                                </div>
                                <div style="width:17%">
                                    <span>采购员：</span>
                                    <span>{{scope.row.purchaseByName}}</span>
                                </div>
                                <div style="width:17%">
                                    <span>收货人：</span>
                                    <span>采购订单</span>
                                </div>
                                <div style="width:25%">
                                    <span>创建时间：</span>
                                    <span>{{scope.row.createTime}}</span>
                                </div>
                                <div style="width:17%">
                                    <span>备注：</span>
                                    <span>{{scope.row.tips}}</span>
                                </div>
                            </div>
                            <div style='flex:1;text-align:right;padding-right:0px;display:flex;align-items:center;justify-content:flex-end'>
                                <span 
                                 :style="{color:scope.row.receiveStatus=='done'?'grey':'#92D873',
                                 border:`1px solid ${scope.row.receiveStatus=='done'?'grey':'#92D873'}`,
                                 lineHeight:'1',padding:'2px 2px',borderRadius:'4px'}"
                                >
                                  {{scope.row.receiveStatusName}}
                                </span>
                                <el-button @click="getDetail(scope.row,'detail')" type="text" style="margin-right:10px;margin-left:10px">详情</el-button>
                                <el-dropdown trigger="click">
                                    <span class="el-dropdown-link">
                                        <el-button type="text">操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                                    </span>
                                    <el-dropdown-menu v-if="scope.row.receiveStatus!='done'" slot="dropdown">
                                        <el-dropdown-item @click.native="toUpdate(scope.row.id,'update')">编辑</el-dropdown-item>
                                        <el-dropdown-item @click.native='takeDelivery(scope.row)'>收货</el-dropdown-item>
                                        <el-dropdown-item @click.native="getDetail(scope.row,'fastPutStorage')">快捷入库</el-dropdown-item>
                                        <el-dropdown-item>打印产品条码</el-dropdown-item>
                                        <el-dropdown-item @click.native="delItem(scope.row.id)">删除</el-dropdown-item>
                                    </el-dropdown-menu>
                                    <el-dropdown-menu v-if="scope.row.receiveStatus=='done'" slot="dropdown">
                                        <el-dropdown-item @click.native="getQualityCheckInit([scope.row.id],'质检')" v-if="scope.row.noCheckNum>0">质检</el-dropdown-item>
                                        <el-dropdown-item  v-if="scope.row.noCheckNum>0" @click.native="getQualityCheckInit([scope.row.id],'免检')">免检</el-dropdown-item>
                                        <el-dropdown-item>打印产品条码</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                        <div v-else style="">
                            <Picture :url='scope.row.imageUrl' :width='40'></Picture>
                        </div>
                    </tamplate>
                </el-table-column>
                <el-table-column
                    prop="sku"
                    label="品名/SKU"
                    width="width">
                    <template v-if="scope.row.itemList==undefined" slot-scope="scope">
                        <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                        <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    prop=""
                    label="FNSKU/店铺"
                    width="width">
                </el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="noticeQuantity"
                    label="通知收货量"
                    width="width">
                </el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="receiveQuantity"
                    label="收货量"
                    width="width">
                </el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="toCheckQuantity"
                    label="待检量"
                    width="width">
                    <template slot-scope="scope">
                        <span v-if="scope.row.toCheckQuantity==0">{{scope.row.toCheckQuantity}}</span>
                        <el-dropdown v-else>
                            <span>{{scope.row.toCheckQuantity}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>未质检：{{scope.row.notCheckQuantity}}</el-dropdown-item>
                                <el-dropdown-item>质检中：{{scope.row.checkingQuantity}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="hadCheckQuantity"
                    label="已检量"
                    width="width">
                    <template slot-scope="scope">
                        <span v-if="scope.row.hadCheckQuantity==0">{{scope.row.hadCheckQuantity}}</span>
                        <el-dropdown v-else>
                            <span>{{scope.row.hadCheckQuantity}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>可用量：{{scope.row.goodQuantity}}</el-dropdown-item>
                                <el-dropdown-item>次品量：{{scope.row.badQuantity}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="checkStatus"
                    label="质检状态"
                    width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.checkStatus=='toCheck'">未质检</span>
                        <span v-if="scope.row.checkStatus=='allCheck'">全部质检</span>
                        <span v-if="scope.row.checkStatus=='partCheck'">部分质检</span>
                    </template>
                </el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="checkNo"
                    label="质检单"
                    width="200">
                    <template slot-scope="scope">
                        <el-popover
                        placement="right"
                        width="300"
                        trigger="click">
                        <el-table :data="scope.row.checkNoteList"
                        :cell-style="{ fontSize: '13px',}"
                        :header-cell-style="{
                            fontSize: '13px',
                        }">
                            <el-table-column 
                            header-align="center"
                            align="center"
                            width="150" property="qcNo" label="质检单号(跳转)">
                                <template slot-scope="item">
                                    <el-button  @click="toQuality(item.row.id)" type="text">{{item.row.qcNo}}</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column  
                            header-align="center"
                            align="center"
                            min-width="100" property="statusName" label="状态">
                                <template slot-scope="item">
                                    <span type="text">{{item.row.statusName}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <template slot="reference">
                            <span v-for="(item,index) in scope.row.checkNoteList" :key="item.qcNo">
                                <el-button type="text" v-if="index==0">{{item.qcNo}}</el-button><i v-if="index==1" class="el-icon-arrow-down"></i>
                            </span>
                        </template>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column
                header-align="center"
                    prop="prop"
                    label="操作"
                    width="200">
                </el-table-column>
            </el-table>
        </div>
        <div style="text-align:center;border-top:1px solid #E4E7ED;padding:10px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
        <!-- 添加收货单 -->
        <el-dialog
            :destroy-on-close='true'
            :visible.sync="addOrderBox"
            width="70%"
            :before-close="OrderBoxClose">
            <div style="display:flex;align-items:center;justify-content:space-between;padding-right:60px"  slot="title">
                <div style="display:flex;align-items:center">
                    <h3 style="margin-right:10px">{{title}} </h3>
                    <span v-if="title!='添加收货单'" :style="{color:receiveStatusName=='已完成'?'grey':'#92D873',border:`1px solid ${receiveStatusName=='已完成'?'grey':'#92D873'}`,lineHeight:'1',padding:'2px 2px',borderRadius:'4px'}">{{receiveStatusName}}</span>
                </div>
                <div v-if="boxType=='detail'&&receiveStatusName=='待收货'">
                    <el-dropdown trigger="click">
                        <el-button>操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>打印产品条码</el-dropdown-item>
                            <el-dropdown-item @click.native='delItem(addInfo.id)'>删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-button @click="toUpdate" style="margin-left:10px">编辑</el-button>
                    <el-button @click="boxType='fastPutStorage'">快捷入库</el-button>
                    <el-button type="primary" @click="boxType='take'">收货</el-button>
                </div>
                <div v-if="boxType=='detail'&&receiveStatusName=='已完成'">
                    <el-button>打印产品条码</el-button>
                    <el-button @click="getQualityCheckInit([addInfo.id],'质检')">质检</el-button>
                    <el-button type="primary" @click="getQualityCheckInit([addInfo.id],'免检')">免检</el-button>
                </div>
            </div>
            <div v-loading='dialogLoading'>
                <el-steps size='mini' v-if="boxType!='add'" style="width:80%" :active="boxType=='update'?1:receiveStatusName=='待收货'?1:3">
                    <el-step title="创建"  :description="createByName +'-'+ createTime"></el-step>
                    <el-step title="收货">
                        <span slot="description">
                            <span v-if="receiveByName!=''">{{receiveByName +'-'+ receiveTime}}</span>
                        </span>
                    </el-step>
                    <el-step title="完成">
                        <span slot="description">
                            <span v-if="receiveByName!=''">{{receiveByName +'-'+ receiveTime}}</span>
                        </span>
                    </el-step>
                </el-steps><br><br>
                <el-form ref="addInfo" :model="addInfo" :inline='true' label-width="100px">
                    <el-form-item label="收货仓库"
                    prop="storageId"
                    :rules="[{required:true,message:'请选择',trigger:'change'}]">
                        <el-select :disabled="boxType=='detail'||boxType=='take'||boxType=='update'||boxType=='fastPutStorage'||$route.query.order_id" v-model="addInfo.storageId" placeholder="收货仓库">
                            <el-option
                                @click.native="selectStorage(item)"
                                v-for="item in storageList"
                                :key="item.id"
                                :label="item.value"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="收货类型" prop="receiveType">
                        <el-select :disabled='true' v-model="addInfo.receiveType" placeholder="">
                            <el-option
                                label="采购订单"
                                value="purchase">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="来源单据号"
                    prop="purchaseNo"
                    :rules="[{required:true,message:'请选择',trigger:'change'}]">
                        <el-select @visible-change="getPurchaseNoList" :disabled="!addInfo.storageId||boxType=='detail'||boxType=='take'||boxType=='update'||boxType=='fastPutStorage'||$route.query.order_id?true:false" v-model="addInfo.purchaseNo" placeholder="来源单据号">
                            <el-option
                                @click.native="getPurchase(item)"
                                v-for="item in purchaseList"
                                :key="item.id"
                                :label="item.purchaseNo"
                                :value="item.purchaseNo">
                            </el-option>
                            <!-- <el-option>
                                <el-button type="text" @click="purchaseBox=true">更多来源单据号</el-button>
                            </el-option> -->
                        </el-select>
                    </el-form-item>
                    <el-form-item label="供应商"
                    prop="supplierId">
                        <el-select :disabled='true' v-model="addInfo.supplierId" placeholder="供应商">
                            <el-option
                                v-for="item in supplier"
                                :key="item.id"
                                :label="item.value"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="采购员"
                    prop="purchaseBy">
                        <el-select :disabled='true' v-model="addInfo.purchaseBy" placeholder="采购员">
                            <el-option
                                v-for="item in purchaseList"
                                :key="item.id"
                                :label="item.purchaseByName"
                                :value="item.purchaseBy">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="预计到货时间"
                    prop="expectArrivalTime">
                        <el-date-picker
                        style="width:200px"
                        :disabled="boxType=='detail'"
                        v-model="addInfo.expectArrivalTime"
                        type="date"
                        format='yyyy-MM-dd'
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="物流商"
                    prop="logisticsId"
                    :rules="[{required:required,message:'请输入物流商',trigger:'change'}]">
                        <el-select :disabled="boxType=='detail'" v-model="addInfo.logisticsId" placeholder="物流商">
                            <el-option
                                v-for="item in transferTypeList"
                                :key="item.id"
                                :label="item.value"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="物流单号"
                    prop="logisticsNo"
                    :rules="[{required:addInfo.logisticsId?true:false,message:'请输入物流单号',trigger:'blur'}]">
                        <el-input :disabled="boxType=='detail'" v-model="addInfo.logisticsNo" style="width：200px" placeholder="物流单号"></el-input>
                    </el-form-item>
                    <el-form-item label="费用分配方式">
                        <el-select :disabled='true' v-model="addInfo.freightShareType" placeholder="费用分配方式">
                            <el-option
                                v-for="item in freightShareTypeList"
                                :key="item.id"
                                :label="item.value"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="运费"
                    prop="freight">
                        <el-input :disabled="boxType=='detail'" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" style="width:200px" v-model="addInfo.freight" placeholder="运费">
                            <span slot="append">{{currency}}</span>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="其他费用"
                    prop="otherFee">
                        <el-input :disabled="boxType=='detail'" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="addInfo.otherFee" style="width:200px" placeholder="运费">
                            <span slot="append">{{currency}}</span>
                        </el-input>
                    </el-form-item><br>

                    <el-form-item label="附件" prop="fileId">
                        <el-upload
                        :action="action"
                        :disabled="boxType=='detail'"
                        :headers="{token:mytoken.token}"
                        :on-success="uploadSuccess"
                        :before-remove="beforeRemove"
                        multiple
                        :show-file-list="false"
                        :on-exceed="handleExceed"
                        >
                            <el-button :disabled="boxType=='detail'" size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">不得超过10M</div>
                        </el-upload>
                        <div>
                            <p style="cursor:pointer" v-for="(item,index) in fileList" :key="index"><i style="color:orange" class="el-icon-paperclip"></i>&nbsp;<span @click="down(item)">{{item.name}}</span> <i v-if="boxType!='detail'" @click="handleRemove(item)" class="el-icon-close" style="cursor:pointer"></i></p>
                        </div>
                    </el-form-item>
                    <br>
                    <el-form-item label="备注" prop="tips">
                        <el-input :disabled="boxType=='detail'" v-model="addInfo.tips" type="textarea" placeholder="备注" rows="4" cols='100'></el-input>
                    </el-form-item>
                </el-form>
                <div v-if="title=='添加收货单'||boxType=='take'||boxType=='update'" style="margin-top:30px">
                    <el-button v-show="boxType!='take'||boxType=='update'" @click="pushProduct">添加产品</el-button>
                    <el-button v-show="boxType!='take'||boxType=='update'" @click="openBatchUpload('商品')">导入产品</el-button>
                    <el-button v-show="boxType=='take'" @click="openBatchUpload('收货量')">导入收货量</el-button>
                    <br>
                    <el-table
                    :data='addGoodsList'
                    style="margin-top:10px"
                    :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                    :header-cell-style="{
                        fontSize: '13px',
                        padding: '9px 0',
                        marginTop: '10px',
                        background: '#EBEEF5',
                    }">
                        <el-table-column
                            prop="prop"
                            label="图片" >
                            <template slot-scope="scope">
                                <Picture :url='scope.row.imageUrl' width='40'></Picture>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="prop"
                            label="品名/SKU" >
                            <template slot-scope="scope">
                                <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                                <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="prop"
                            label="FNSKU/店铺" >
                        </el-table-column>
                        <el-table-column
                            v-if="title=='添加收货单'||boxType=='update'"
                            prop="actualPurchaseNumber"
                            label="采购量" >
                        </el-table-column>
                        <el-table-column
                            v-if="title=='添加收货单'||boxType=='update'"
                            prop="toPutNumber"
                            label="待到货量" >
                        </el-table-column>
                        <el-table-column
                            v-if="title=='添加收货单'||boxType=='update'"
                            prop="hadNoticeQuantity"
                            label="已通知" >
                        </el-table-column>
                        <el-table-column
                            v-if="title=='添加收货单'||boxType=='update'"
                            prop="toNoticeNumber"
                            label="未通知" >
                        </el-table-column>
                        <el-table-column
                            width="150"
                            prop="noticeQuantity"
                            label="通知收货量" >
                            <template slot="header"
                            v-if="title=='添加收货单'||boxType=='update'"
                            >
                            <i style="color:red">*</i> <span>通知收货量</span><span type="text" @click="all" style="color:#1989FA">（全部）</span>
                            </template>
                            <template slot-scope="scope">
                                <el-input  
                                v-if="title=='添加收货单'||boxType=='update'" 
                                oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" 
                                @change="scope.row.noticeQuantity=scope.row.noticeQuantity>scope.row.toNoticeNumber?scope.row.toNoticeNumber:scope.row.noticeQuantity"
                                v-model="scope.row.noticeQuantity" 
                                placeholder="通知收货量"
                                ></el-input>
                                <span  v-if="boxType=='take'">{{scope.row.noticeQuantity}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="boxType=='take'" prop="receiveQuantity" label="收货量">
                            <template slot="header">
                                <span>收货量</span><el-button @click="takeAll" type="text">(全部)</el-button>
                            </template>
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.receiveQuantity" placeholder="收货量"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="prop"
                            label="备注" >
                            <template slot-scope="scope">
                                <el-input rows="1" type="textarea" v-model="scope.row.childTips" placeholder="备注"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="prop"
                            label="操作" 
                            v-if="title=='添加收货单'||boxType=='update'">
                            <template slot-scope="scope">
                                <el-button @click="addGoodsList.splice(scope.$index,1)" type="text">
                                    移除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 详情+编辑+收货 -->
                <div v-if="boxType=='detail'" style="margin-top:20px">
                    <el-tabs v-model="activeName" type="card" @tab-click="detailTab">
                        <el-tab-pane label="收货明细" name="first">
                            <el-table
                            :data='addGoodsList'
                            style="border-left:1px solid #DCDFE6;border-right:#DCDFE6 1px solid"
                            :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                            :header-cell-style="{
                                fontSize: '13px',
                            }">
                                <el-table-column
                                    prop="prop"
                                    label="图片" >
                                    <template slot-scope="scope">
                                        <Picture :url='scope.row.imageUrl' width='40'></Picture>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="prop"
                                    label="品名/SKU" >
                                    <template slot-scope="scope">
                                        <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                                        <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="prop"
                                    label="FNSKU/店铺" >
                                </el-table-column>
                                <el-table-column
                                    width="150"
                                    prop="noticeQuantity"
                                    label="通知收货量" >
                                </el-table-column>

                                <template v-if="receiveStatusName=='已完成'">
                                    <el-table-column
                                    header-align="center"
                                    align="center"
                                        prop="noticeQuantity"
                                        label="通知收货量"
                                        width="width">
                                    </el-table-column>
                                    <el-table-column
                                        header-align="center"
                                        align="center"
                                        prop="receiveQuantity"
                                        label="收货量"
                                        width="width">
                                    </el-table-column>
                                    <el-table-column
                                        header-align="center"
                                        align="center"
                                        prop="toCheckQuantity"
                                        label="待检量"
                                        width="width">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.toCheckQuantity==0">{{scope.row.toCheckQuantity}}</span>
                                            <el-dropdown v-else>
                                                <span>{{scope.row.toCheckQuantity}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item>未质检：{{scope.row.notCheckQuantity}}</el-dropdown-item>
                                                    <el-dropdown-item>质检中：{{scope.row.checkingQuantity}}</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        header-align="center"
                                        align="center"
                                        prop="hadCheckQuantity"
                                        label="已检量"
                                        width="width">
                                    </el-table-column>
                                    <el-table-column
                                        header-align="center"
                                        align="center"
                                        prop="checkStatus"
                                        label="质检状态"
                                        width="200">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.checkStatus=='toCheck'">未质检</span>
                                            <span v-if="scope.row.checkStatus=='partCheck'">全部质检</span>
                                            <span v-if="scope.row.checkStatus=='allCheck'">部分质检</span>
                                        </template>
                                    </el-table-column>
                                </template>


                                <el-table-column
                                    header-align="center"
                                    align="center"
                                    prop="childTips"
                                    label="备注" >
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="关联单据" name="second">
                            <el-tabs v-model="relevancy">
                                <el-tab-pane label="入库单" name="storage">
                                    <span slot="label">入库单({{getRelatedPutInNoteList.length}})</span>
                                    <el-table
                                        :data="getRelatedPutInNoteList"
                                        style="width: 100%"
                                        :cell-style="{ fontSize: '13px',}"
                                        :header-cell-style="{
                                            fontSize: '13px',
                                        }">
                                        <el-table-column
                                            prop="ibNo"
                                            label="入库单号">
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            label="图片">
                                            <template slot-scope="scope">
                                                <Picture :url='scope.row.imageUrl' width='40'></Picture>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            label="品名/SKU">
                                            <template v-if="scope.row.itemList==undefined" slot-scope="scope">
                                                <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                                                <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            label="店铺FNSKU">
                                        </el-table-column>
                                        <el-table-column
                                            prop="storageName"
                                            label="入库仓库">
                                        </el-table-column>
                                        <el-table-column
                                            prop="operateByName"
                                            label="操作人">
                                        </el-table-column>
                                        <el-table-column
                                            prop="operateTime"
                                            label="操作时间">
                                        </el-table-column>
                                        <el-table-column
                                            prop="goodQuantity"
                                            label="良品量">
                                        </el-table-column>
                                        <el-table-column
                                            prop="badQuantity"
                                            label="次品量">
                                        </el-table-column>
                                        <el-table-column
                                            prop="toCheckQuantity"
                                            label="待检量">
                                        </el-table-column>
                                        <el-table-column
                                            prop="statusName"
                                            label="状态">
                                        </el-table-column>
                                    </el-table>
                                </el-tab-pane>
                                <el-tab-pane label="质检单" name="check">
                                    <span slot="label">质检单({{getRelatedQualityCheckNoteNoList.length}})</span>
                                    <el-table
                                        :data="getRelatedQualityCheckNoteNoList"
                                        style="width: 100%"
                                        :cell-style="{ fontSize: '13px',}"
                                        :header-cell-style="{
                                            fontSize: '13px',
                                        }">
                                        <el-table-column
                                        width="150"
                                            prop="qcNo"
                                            label="质检单号">
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            label="图片" >
                                            <template slot-scope="scope">
                                                <Picture :url='scope.row.imageUrl' width='40'></Picture>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            label="品名/SKU" >
                                            <template slot-scope="scope">
                                                <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                                                <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            label="店铺/FNSKU">
                                        </el-table-column>
                                        <el-table-column
                                            prop="storageName"
                                            label="仓库">
                                        </el-table-column>
                                        <el-table-column
                                            prop="checkByName"
                                            label="质检员">
                                        </el-table-column>
                                        <el-table-column
                                            prop="checkTime"
                                            label="质检时间">
                                        </el-table-column>
                                        <el-table-column
                                            prop="checkingQuantity"
                                            label="质检量">
                                        </el-table-column>
                                        <el-table-column
                                            prop="goodQuantity"
                                            label="良品量">
                                        </el-table-column>
                                        <el-table-column
                                            prop="badQuantity"
                                            label="次品量">
                                        </el-table-column>
                                        <el-table-column
                                            prop="statusName"
                                            label="状态">
                                        </el-table-column>
                                    </el-table>
                                </el-tab-pane>
                            </el-tabs>
                        </el-tab-pane>
                        <el-tab-pane label="操作记录" name="third">
                            <el-table
                                height="400px"
                                :data="operationLogList"
                                style="width: 100%"
                                :cell-style="{ fontSize: '13px',}"
                                :header-cell-style="{
                                    fontSize: '13px',
                                }">
                                    <el-table-column
                                        prop="createTime"
                                        label="操作时间" 
                                        width="200">
                                    </el-table-column>
                                    <el-table-column
                                        prop="createByName"
                                        label="操作人" 
                                        width="200">
                                    </el-table-column>
                                    <el-table-column
                                        prop="operateTypeName"
                                        label="操作类型" 
                                        width="200">
                                    </el-table-column>
                                    <el-table-column
                                        prop="details"
                                        label="操作详情" >
                                    </el-table-column>
                                </el-table>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <!-- 快捷入库表格 -->
                <div v-if="boxType=='fastPutStorage'">
                    <el-button @click="openBatchUpload('入库量')">导入入库量</el-button><br>
                    <el-table
                    :data='addGoodsList'
                    style="margin-top:10px"
                    :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                    :header-cell-style="{
                        fontSize: '13px',
                        padding: '9px 0',
                        marginTop: '10px',
                        background: '#EBEEF5',
                    }">
                        <el-table-column
                            prop="prop"
                            label="图片" >
                            <template slot-scope="scope">
                                <Picture :url='scope.row.imageUrl' width='40'></Picture>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="prop"
                            label="品名/SKU" >
                            <template slot-scope="scope">
                                <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                                <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="prop"
                            label="FNSKU/店铺" >
                        </el-table-column>
                        <el-table-column
                            prop="noticeQuantity"
                            label="通知收货量" >
                        </el-table-column>
                        <el-table-column
                            prop="goodNum"
                            label="良品量" >
                            <p slot="header">
                                <i style="color:red">*</i> <span>良品量</span>
                                <el-button  type='text' @click="goodAll">(全部)</el-button>
                            </p>
                            <template slot-scope="scope">
                                <el-input 
                                onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')" 
                                v-model="scope.row.goodQuantity" placeholder="良品量"
                                ></el-input>
                            </template>
                                <!-- @blur="scope.row.goodQuantity=scope.row.goodQuantity+scope.row.badQuantity>scope.row.noticeQuantity?scope.row.noticeQuantity-scope.row.badQuantity:scope.row.goodQuantity" -->
                                <!-- @input="scope.row.badQuantity=scope.row.noticeQuantity-scope.row.goodQuantity>=0?scope.row.noticeQuantity-scope.row.goodQuantity:0" -->
                        </el-table-column>
                        <el-table-column
                            prop="badNum"
                            label="次品量" >
                            <p slot="header">
                                <i style="color:red">*</i> <span>次品量</span>
                                <el-button  type='text' @click="badAll">(全部)</el-button>
                            </p>
                            <template slot-scope="scope">
                                <el-input
                                v-model="scope.row.badQuantity" 
                                onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')"
                                placeholder="次品量"></el-input>
                            </template>
                            <!-- @blur="scope.row.badQuantity=scope.row.goodQuantity+scope.row.badQuantity>scope.row.noticeQuantity?scope.row.noticeQuantity-scope.row.goodQuantity:scope.row.badQuantity" -->
                                <!-- @input="scope.row.goodQuantity=scope.row.noticeQuantity-scope.row.badQuantity>=0?scope.row.noticeQuantity-scope.row.badQuantity:0" -->
                        </el-table-column>
                        <el-table-column
                            prop="tips"
                            label="备注">
                            <template slot-scope="scope">
                                <el-input 
                                v-model="scope.row.tips" 
                                placeholder="备注"
                                onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')" ></el-input>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="OrderBoxClose">取消并关闭</el-button>
                <el-button v-show="boxType!='detail'" type="primary" :loading='dialogLoading' @click="sure">确定<span v-if="boxType=='take'">收货</span><span v-if="boxType=='fastPutStorage'">入库</span></el-button>
            </div>
        </el-dialog>
        <!-- 选择来源单据号 -->
        <el-dialog
        :visible.sync="purchaseBox"
        width="70%"
        >
        <h3 slot="title">选择采购单</h3>
        创建时间 
        <el-date-picker
        style="margin-right:10px"
        v-model="value1"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
        </el-date-picker>
        <el-input v-model="model" placeholder="" style="width:300px">
            <p style="width:60px" slot="prepend">订单号</p>
        </el-input>
        <el-table
            :data="data"
            style="width: 100%;margin-top:15px"
            :cell-style="{ fontSize: '13px',}"
            :header-cell-style="{
                fontSize: '13px',
                background:'#EBEEF5'
            }">
            <el-table-column
                prop="prop"
                label="订单号"
                width="width">
            </el-table-column>
            <el-table-column
                prop="prop"
                label="创建时间"
                width="width">
            </el-table-column>
            <el-table-column
                prop="prop"
                label="订单号"
                width="width">
            </el-table-column>
            <el-table-column
                prop="prop"
                label="品名/SKU"
                width="width">
            </el-table-column>
            <el-table-column
                prop="prop"
                label="FNSKU/店铺"
                width="width">
            </el-table-column>
            <el-table-column
                prop="prop"
                label="采购量"
                width="width">
            </el-table-column>
            <el-table-column
                prop="prop"
                label="待到货量"
                width="width">
            </el-table-column>
            <el-table-column
                prop="prop"
                label="操作"
                width="width">
            </el-table-column>
        </el-table>
        </el-dialog>
        <!-- 选择商品 -->
        <el-dialog
            title="添加商品"
            :visible.sync="selectGoodsBox"
            width="900px"
            append-to-body
            :before-close="selectBoxClose">
            <div v-loading='dialogLoading'>
                <el-input v-model="goodsListInfo.keyword" placeholder="SKU\品名\FNSKU" style="width:250px;margin-right:10px"></el-input>
                <el-button type="primary" @click="getPurchaseProductInfo('find')">查询</el-button>
                <el-checkbox true-label='1' false-label='0'  style="float:right" @change="getPurchaseProductInfo('find')" v-model="goodsListInfo.isFilterZero" >隐藏未通知量为0的数据</el-checkbox>
                <el-table
                :data='goodsList'
                height='400'
                ref="goodsTable"
                @select="selectGoods"
                @select-all="selectAll"
                style="width: 100%;margin-top:10px"
                :cell-style="{ fontSize: '13px', padding: '7px 0',textAlign:'center' }"
                :header-cell-style="{
                    fontSize: '13px',
                    padding: '9px 0',
                    marginTop: '10px',
                    background: '#EBEEF5',
                    textAlign:'center'
                }">
                    <el-table-column
                        type="selection"
                        width="55px" >
                    </el-table-column>
                    
                    <el-table-column
                        prop="prop"
                        label="图片" >
                        <template slot-scope="scope">
                            <Picture :url='scope.row.imageUrl' width='40'></Picture>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="品名/SKU" >
                        <template slot-scope="scope">
                            <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                            <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="FNSKU/店铺" >
                    </el-table-column>
                    <el-table-column
                        prop="actualPurchaseNumber"
                        label="采购量" >
                    </el-table-column>
                    <el-table-column
                        prop="toPutNumber"
                        label="待到货量" >
                    </el-table-column>
                    <el-table-column
                        prop="hadNoticeNumber"
                        label="已通知" >
                    </el-table-column>
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="toNoticeNumber"
                        label="未通知" >
                    </el-table-column>
                </el-table>
                <div style="text-align:center;padding-top:10px">
                    <el-pagination
                    @size-change="goodsSizeChange"
                    @current-change="goodsCurrentChange"
                    :current-page="goodsListInfo.current"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="goodsListInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="goodsListInfo.total">
                    </el-pagination>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="selectGoodsBox = false">取 消</el-button>
                <el-button type="primary" @click="addProduct">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 批量导入 -->
        <el-dialog
            :title="'批量导入'+batchUploadType"
            :visible.sync="batchUpload"
            width="600px"
            :destroy-on-close='true'
            :before-close="batchUploadClose">
            <div>
            <el-upload
            class="upload-demo"
            :action="action2"
            :headers="{...mytoken,purchaseId:mytoken.purchaseId?mytoken.purchaseId:null,id:addInfo.id?addInfo.id:0}"
            :on-success="uploadSuccessRes"
            multiple
            :on-exceed="handleExceed"
            :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <el-button @click="downTemplate" slot="tip" type="text" style="margin-left:10px">下载模板</el-button>
            <div slot="tip" class="el-upload__tip">
                <ul style="list-style:disc;padding-left:20px;line-height:20px">
                    <li style="list-style:disc">支持导入格式为xlsx的文件(手动修改文件后缀无效)</li>
                    <li style="list-style:disc">模版中的表头不可更改，不可删除</li>
                    <li style="list-style:disc">数据记录不要超过500条</li>
                    <template v-if="batchUploadType!='商品'">
                        <li style="list-style:disc">导入成功则直接生效</li>
                        <li style="list-style:disc">当同一收货单中有一条数据错误时，整张收货单的数据都需重新导入；可下载失败原因修改后再次导入</li>
                        <li style="list-style:disc">同一收货单，本次运费和本次其它费用只取第一个有效值</li>
                    </template>
                    <li v-else>同一个SKU+FNSKU存在多条记录，以第一条为准</li>
                </ul>
                <p v-if="faildList.length>0" style="font-size:16px">上传失败项：</p><br>
                <el-table
                    :data="faildList"
                    v-if="faildList.length>0"
                    style="width: 100%"
                    :cell-style="{ fontSize: '13px', padding: '7px 0',textAlign:'center' }"
                    :header-cell-style="{
                        fontSize: '13px',
                        padding: '9px 0',
                        marginTop: '10px',
                        background: '#EBEEF5',
                        textAlign:'center'
                    }">
                    <el-table-column
                        prop="value"
                        label="失败原因"
                        width="width">
                        <span slot-scope="scope">{{scope.row.value}}</span>
                    </el-table-column>
                </el-table>
            </div>
            </el-upload>
            </div>
            <div slot="footer">
                <el-button @click="batchUploadClose">取 消</el-button>
                <el-button type="primary" @click="batchUploadClose">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 质检+免检 -->
        <el-dialog
            :title="checkTypeTitle"
            :visible.sync="qualityTestBox"
            width="width"
            append-to-body
            :before-close="qualityTestBoxClose">
            <div>
                <el-table
                    :data="checkList"
                    style="width: 100%"
                    :cell-style="{ fontSize: '13px', padding: '7px 0',textAlign:'center' }"
                    :header-cell-style="{
                        fontSize: '13px',
                        padding: '9px 0',
                        marginTop: '10px',
                        background: '#EBEEF5',
                        textAlign:'center'
                    }">
                    <el-table-column
                        prop="crNo"
                        label="收货单号">
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="图片" >
                        <template slot-scope="scope">
                            <Picture style="margin:auto" :url='scope.row.imageUrl' width='40'></Picture>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="品名/SKU" >
                        <template slot-scope="scope">
                            <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                            <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="FNSKU/店铺" >
                    </el-table-column>
                    <el-table-column
                        prop="notCheckQuantity"
                        label="未质检">
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="本次质检量">
                        <template slot="header">本次<span>{{checkTypeTitle}}</span>量<span @click="checkAll" style="color:#61A4FD">（全部）</span></template>
                        <template slot-scope="scope">
                            <el-input 
                            oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" 
                            @change="scope.row.checkingQuantity=scope.row.checkingQuantity>scope.row.notCheckQuantity?scope.row.notCheckQuantity:scope.row.checkingQuantity"
                            v-model="scope.row.checkingQuantity" 
                            placeholder="质检量"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div slot="footer">
                <el-button @click="qualityTestBoxClose">取 消</el-button>
                <el-button :loading='dialogLoading' type="primary" @click="toCheck">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {pageInit,
        listPage,
        addInit,
        getPurchaseNoList,
        getPurchaseProductInfo,
        warehouseReceiveNote,
        remove,
        detail,
        receive,
        updateInit,
        qualityCheckInit,
        batchQualityCheck,
        getFile,
        update,
        receiveQuickPutIn,
        quickPutInForReceiveNote,
        delFile,
        getRelatedPutInNote,
        purchaseCreateReceiveInit,
        getRelatedQualityCheckNoteNo,
        operationLog,
        batchRemove
        } from '@/api/InventoryManage/ReceivingOrder.js'
import ShopsAndSites from '@/components/ShopsAndSites'
export default {
components:{
    ShopsAndSites
},
data(){
    var token = window.sessionStorage.getItem('token')
    return{
        mytoken:{token:token,purchaseId:'',id:''},
        purchaseByName:'',

        createTime:'',
        createByName:'',
        receiveStatusName:'',
        receiveByName:'',
        receiveTime:'',

        selectList:[],

        topTab:'',
        checkStatus:'',
        required:false,
        dialogLoading:false,
        boxType:'',
        shopAndMar:'',
        purchaseBox:false,
        currency:'CNY',
        loading:false,
        topTab:'first',
        tableData:[],
        title:'',
        checkTypeTitle:'',
        addOrderBox:false,
        selectGoodsBox:false,
        batchUpload:false,
        qualityTestBox:false,
        checkStatus:[],
        searchTime:[],
        searchTimeType:[],
        shopList:[],
        total:0,
        time:[],
        pageInfo:{
            current:1,
            shopId:'',
            marketPlaceId:'',
            checkStatus:'',
            receiveStatus:'',
            timeType:'expect_arrival_time',
            beginTime:'',
            endTime:'',
            searchType:'cr_no',
            keyword:'',
            pageSize:'',
        },
        // 新增
        pickerOptions:{
            disabledDate(time){
                return  time.getTime() < new Date().getTime() - 8.64e7 ;
            }
        },
        freightShareTypeList:[],
        storageList:[],
        supplier:[],
        transferTypeList:[],
        purchaseList:[],
        fileList:[],
        addInfo:{
            receiveType:'purchase',
            expectArrivalTime:'',
            fileIdList:[],
            freight:'',
            freightShareType:'',
            logisticsId:'',
            logisticsNo:'',
            otherFee:'',
            purchaseBy:'',
            purchaseNo:'',
            storageId:'',
            supplierId:'',
            tips:''
        },
        goodsList:[],
        selectedList:[],
        addGoodsList:[],
        goodsListInfo:{
            pageSize:10,
            current:1,
            keyword:'',
            total:0,
            isFilterZero:'1',
        },
        // 详情
        activeName:'first',
        // 详情关联单据
        getRelatedQualityCheckNoteNoList:[],
        getRelatedPutInNoteList:[],
        relevancy:'storage',

        // 质检
        checkList:[],
        // 操作日志
        operationLogList:[],
        faildList:[],  //导入失败
        batchUploadType:''
    }
},
computed:{
    action(){
        return process.env.VUE_APP_API_URL+'/api/file/upload/uploadFile'
    },
    action2(){
        if(this.batchUploadType=='收货'){
            return process.env.VUE_APP_API_URL+'/api/warehouseReceiveNote/importReceiveFile'
        }else if(this.batchUploadType=='快捷入库'){
            return process.env.VUE_APP_API_URL+'/api/warehouseReceiveNote/importReceiveQuickInFile'
        }else if(this.batchUploadType=='商品'){
            return process.env.VUE_APP_API_URL+'/api/warehouseReceiveNote/importProductWhenAddReceive'
        }else if(this.batchUploadType=='入库量'){
            return process.env.VUE_APP_API_URL+'/api/warehouseReceiveNote/importReceiveQuantityWhenQuickIn'
        }else if(this.batchUploadType=='收货量'){
            return process.env.VUE_APP_API_URL+'/api/warehouseReceiveNote/importProductWhenReceive'
        }
    }
},
created(){
    this.getListPage()
},
watch:{
    time:{
        handler(newval,oldval){
            if(newval==null){
                this.pageInfo.beginTime=''
                this.pageInfo.endTime=''
                this.getListPage()
            }
        },
        deep:true
    },
    $route(to){
        console.log(to);
        if(to.query.order_id){
            this.purchaseCreateReceiveInit(to.query.order_id)
            console.log(this.addGoodsList);
        }
    }
},
methods:{
    // 表格合并单元格
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
        if (row.itemList!=undefined) {
            if (columnIndex == 1) {
                return [1, 10];
            } else {
                return [1, 1];
            }
        }else{
            [0,1]
        }
    },
    clearShop(){
        this.shopAndMar=''
        this.getListPage()
    },
    openBatchUpload(type){
        if(type=='商品'){
            this.$refs.addInfo.validate(valid=>{
                if(!valid) return
                this.batchUploadType=type
                this.batchUpload=true
            })
        }else{
            this.batchUploadType=type
            this.batchUpload=true
        }
        
    },
    batchUploadClose(){
        this.batchUpload=false
        this.fileList=[]
        this.faildList=[]
    },
    downTemplate(){
        if(this.batchUploadType=='收货'){
            window.location.href='收货单-批量收货模版.xlsx'
        }else if(this.batchUploadType=='快捷入库'){
            window.location.href='收货单-批量快捷入库模版.xlsx'
        }else if(this.batchUploadType=='商品'){
            window.location.href='收货单-导入产品模版.xlsx'
        }else if(this.batchUploadType=='入库量'){
            window.location.href='收货单-导入入库量模版.xlsx'
        }else if(this.batchUploadType=='收货量'){
            window.location.href='收货单-导入收货量模版.xlsx'
        }
    },
    takeAll(){
        this.addGoodsList.forEach(item=>{
            item.receiveQuantity=item.noticeQuantity
        })
    },
    batchRemove(){
        if(this.selectList.length==0) return this.$message.error('请选择具体内容')
        
        const arr = this.selectList.filter(item=>{
            return item.receiveStatus=='toReceive'
        })

        if(arr.length!=this.selectList.length) return this.$message.error('请选择待收货状态的收货单');

        this.$confirm(`共${arr.length}个收货单, 确认删除?删除后不可恢复`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            batchRemove({ids:arr.map(item => item.id)}).then(res=>{
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListPage()
                }else{
                    this.$message.error(res.data.message)
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });          
        });
    },
    operationLog(id,type){
        operationLog({objectId:id,operateObject:type}).then(res=>{
            this.operationLogList=res.data.data
        })
    },
    selectBoxClose(){
        this.goodsListInfo.isFilterZero='1'
        this.selectGoodsBox=false
    },
    purchaseCreateReceiveInit(id){
        this.loading=true
        purchaseCreateReceiveInit({ids:[id]}).then(res=>{
            this.loading=false
            console.log(res.data.data);
            if(res.data.code==200){
                
                for(var key in this.addInfo){
                    if(!res.data.data[0][key]) continue
                    this.addInfo[key]=res.data.data[0][key]
                }
                this.getPurchaseNoList()
                this.addInfo.purchaseBy=res.data.data[0].createBy
                this.addGoodsList=res.data.data[0].purchaseProductInfoResList.map(item=>{
                    item.title=item.commodityName
                    item.sku=item.commoditySku
                    item.hadNoticeQuantity=item.hadNoticeNumber
                    item.noticeQuantity=0
                    return item
                })
                this.addStart('添加收货单')
            }else{
                this.$message.error(res.data.message)
                this.$router.push('/ReceivingOrder')
            }
        })
    },

    // 详情关联单据
    getRelatedQualityCheckNoteNo(id){
        getRelatedQualityCheckNoteNo(id).then(res=>{
            this.getRelatedQualityCheckNoteNoList=res.data.data
        })
    },
    getRelatedPutInNote(id){
        getRelatedPutInNote(id).then(res=>{
            this.getRelatedPutInNoteList=res.data.data
        })
    },
    toBatchQuality(info){
        const flag = this.selectList.some(item=> item.receiveStatus!='done')
        if(this.selectList.length==0) return this.$message.error('请选择内容')
        if(flag) return this.$message.error('请选择已完成状态的收货单');
        this.getQualityCheckInit(this.selectList.map(item=>item.id),info)
    },
    down(info){
        if(!info.id) return
        if(this.title=='添加收货单'||this.boxTy=='update') return
        this.dialogLoading=true
        getFile(info.fileId).then(res=>{
            this.dialogLoading=false
            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel application/x-excel;charset=utf-8'});
            const downloadElement = document.createElement('a');
            const href = window.URL.createObjectURL(blob); // 创建下载的链接
            downloadElement.href = href;
            downloadElement.download =info.name; // 下载后文件名
            document.body.appendChild(downloadElement);
            downloadElement.click(); // 点击下载
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(href); // 释放掉blob对象
        })
    },
    toQuality(id){
        console.log(id);
        this.$router.push({
            path:'/quality',
            query:{
                id:id
            }
        })
    },
    badAll(){
        console.log(this.addGoodsList);
        this.addGoodsList.forEach(item=>{
            item.badQuantity=item.noticeQuantity
            item.goodQuantity=0
        })
    },
    goodAll(){
        console.log(this.addGoodsList);
        this.addGoodsList.forEach(item=>{
            item.goodQuantity=item.noticeQuantity
            item.badQuantity=0
        })
    },
    getPageTime(val){
        this.pageInfo.beginTime=val[0]
        this.pageInfo.endTime=val[1]
        this.getListPage()
    },
    toCheck(){
        const flag = this.checkList.some(item=>{
            return item.checkingQuantity!=0&&item.checkingQuantity!=''
        })
        console.log(flag);
        if(!flag) return this.$message.error('至少填写一个质检量')
        this.dialogLoading=true

        var list = []
        this.checkList.forEach(item=>{
            if(item.checkingQuantity!=0&&item.checkingQuantity!=''){
                list.push({
                    checkingQuantity:item.checkingQuantity,
                    receiveId:item.receiveId,
                    status:this.checkTypeTitle=='质检'?'checking':'exemptChecked'
                })
            }
        })
        batchQualityCheck({list:list}).then(res=>{
            this.dialogLoading=false
            if(res.data.code==200){
                this.$message.success(res.data.message)
                this.qualityTestBoxClose()
                this.getListPage()
                this.OrderBoxClose()
            }else{
                this.$message.error(res.data.message)
            }
        })
    },
    qualityTestBoxClose(){
        this.qualityTestBox=false
        this.checkList=[]
    },
    checkAll(){
        this.checkList.forEach(item=>{
            item.checkingQuantity=item.notCheckQuantity
        })
    },
    getQualityCheckInit(ids,title){
        this.checkTypeTitle=title
        this.dialogLoading=true
        this.loading=true
        qualityCheckInit({ids:ids}).then(res=>{
            this.dialogLoading=false
            this.loading=false
            if(res.data.code==200){
                this.qualityTestBox=true
                console.log(res);
                this.checkList=res.data.data.map(item=>{
                    item.checkingQuantity=''
                    item.receiveId=item.id
                    item.status=''
                    return item
                })
            }else{
                this.$message.error(res.data.message)
            }
        })
    },
    all(){
        this.addGoodsList.forEach(item=>{
            item.noticeQuantity=item.toNoticeNumber
        })
    },
    toUpdate(id,type){
        this.boxType='update'
        if(type=='update'){
            this.getUpdateInit(id)
        }else {
            this.getUpdateInit(this.addInfo.id)
        }
    },
    changeStatuse(){
        console.log(this.topTab);
        if(this.topTab=='first'){
            this.pageInfo.receiveStatus=''
            this.getListPage()
        }else{
            this.pageInfo.receiveStatus=this.topTab
            this.getListPage()
        }
    },
    getUpdateInit(id){
        this.dialogLoading=true
        updateInit(id).then(res=>{
            this.dialogLoading=false
            this.purchaseList=res.data.mapInfo.purchaseNoList
            this.purchaseList.forEach(item=>{
                if(item.purchaseNo==res.data.data.purchaseNo){
                    this.mytoken.purchaseId=item.id
                }
            })
            for(var key in this.addInfo){
                this.addInfo[key]=res.data.data[key]
            }
            this.addInfo.fileIdList=res.data.data.fileList.map(item=> item.fileId)
            this.title=res.data.data.crNo
            this.addInfo.id=res.data.data.id
            this.purchaseByName=res.data.data.purchaseByName
            this.checkStatus=res.data.data.checkStatus

            this.createByName=res.data.data.createByName
            this.createTime=res.data.data.createTime
            this.receiveStatusName=res.data.data.receiveStatusName
            this.receiveByName=res.data.data.receiveByName
            this.receiveTime=res.data.data.receiveTime
            if(res.data.data.fileList.length>0){
                this.fileList=res.data.data.fileList.map(item=>{
                    return {
                        ...item,
                        name:item.fileName,
                    }
                })
            } 
            console.log(this.addInfo);
            this.addGoodsList=res.data.data.itemList

            this.getAddInit()
        })
    },
    getDetail(info,boxType){
        this.title=info.crNo
        this.addInfo.id=info.id
        this.boxType=boxType
        this.purchaseByName=info.purchaseByName
        this.checkStatus=info.checkStatus
        this.loading=true
        detail(info.id).then(res=>{
            this.purchaseList=res.data.mapInfo.purchaseNoList
            this.loading=false
            for(var key in this.addInfo){
                this.addInfo[key]=res.data.data[key]
            }
            this.addInfo.fileIdList=res.data.data.fileList.map(item => item.fileId)
            this.createByName=res.data.data.createByName
            this.createTime=res.data.data.createTime
            this.receiveStatusName=res.data.data.receiveStatusName
            this.receiveByName=res.data.data.receiveByName
            this.receiveTime=res.data.data.receiveTime
            if(res.data.data.fileList.length>0){
                this.fileList=res.data.data.fileList.map(item=>{
                    return {
                        ...item,
                        name:item.fileName,
                    }
                })
            } 
            console.log(this.fileList);

            this.addGoodsList=res.data.data.itemList.map(item=>{
                if(boxType=='take'){
                    if(item.receiveQuantity==0||!item.receiveQuantity){
                        item.receiveQuantity=item.noticeQuantity
                    }
                }
                return item
            })
            this.getAddInit()
        })
    },
    takeDelivery(row){
        this.getDetail(row,'take')
    },
    goodsSizeChange(val){
        this.goodsListInfo.pageSize=val
    },
    delItem(id){
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.loading=true
            remove(id).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.tableData.forEach((item,index)=>{
                        if(item.id==id) this.tableData.splice(index,1)
                    })
                    if(this.boxType=='detail'){
                        this.OrderBoxClose()
                    }
                    this.getPageInit()
                }else{
                    this.$message.error(res.data.message)
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });          
        });
    },
    OrderBoxClose(){
        this.boxType=''
        this.$refs.addInfo.resetFields()
        this.addInfo={
            receiveType:'purchase',
            expectArrivalTime:'',
            fileIdList:[],
            freight:'',
            freightShareType:'',
            logisticsId:'',
            logisticsNo:'',
            otherFee:'',
            purchaseBy:'',
            purchaseNo:'',
            storageId:'',
            supplierId:'',
            tips:''
        }
        this.fileList=[]
        this.purchaseList=[]
        this.addOrderBox=false
        this.addInfo.id=null
        this.addInfo.fileId=''
        this.addGoodsList=[]
        this.activeName='first'
        this.getRelatedQualityCheckNoteNoList=[]
        this.getRelatedPutInNoteList=[]
        if(this.$route.query.order_id) this.$router.push('/ReceivingOrder')
        this.goodsListInfo.isFilterZero='1'
    },
    detailTab(){
        // if(this.getRelatedQualityCheckNoteNoList.length>0&&this.getRelatedPutInNoteList.length>0){
        //     this.relevancy='storage'
        // }
        // if(this.getRelatedQualityCheckNoteNoList.length>0&&this.getRelatedPutInNoteList.length==0){
        //     this.relevancy='check'
        // }
        // if(this.getRelatedQualityCheckNoteNoList.length==0&&this.getRelatedPutInNoteList.length!=0){
        //     this.relevancy='storage'
        // }
        if(this.activeName=='second'){
            this.getRelatedQualityCheckNoteNo(this.addInfo.id)
            this.getRelatedPutInNote(this.addInfo.id)
        }else if (this.activeName=='third') {
            this.operationLog(this.addInfo.id,'receive')
        }
    },
    getShopId(shop,mar){
        this.pageInfo.shopId=shop
        this.pageInfo.marketPlaceId=mar
        this.getListPage()
    },
    sure(){
        this.$refs.addInfo.validate(valid=>{
            if(!valid) return
            if(this.addGoodsList.length==0) return this.$message.error('请选择商品');
            var num = this.addGoodsList.some(item=>{
                return item.noticeQuantity==''||item.noticeQuantity==0
            })
            if(num) return this.$message.error('通知收货量为必填项')
            let itemList = this.addGoodsList.map(item=>{
                if(this.boxType=='take'){    //收货
                    return{
                        id:item.childId,
                        receiveQuantity:item.receiveQuantity,
                        tips:item.childTips
                    }
                }else if(this.title=='添加收货单'){
                    return {
                        noticeQuantity:item.noticeQuantity,
                        productId:item.productId,
                        purchaseProductId:item.id,
                        tips:item.childTips
                    }
                }else if(this.boxType=='update'){    //编辑
                    return{
                        id:item.childId?item.childId:null,
                        noticeQuantity:item.noticeQuantity,
                        productId:item.productId,
                        purchaseProductId:item.id,
                        tips:item.childTips
                    }   
                }else if(this.boxType=='fastPutStorage') {  //快捷入库
                    return{ 
                        badQuantity:item.badQuantity,
                        goodQuantity:item.goodQuantity,
                        id:item.childId,
                        tips:item.tips,
                    }
                }  
            })
            this.dialogLoading=true
            if(this.title=='添加收货单'){
                warehouseReceiveNote({...this.addInfo,itemList:itemList}).then(res=>{
                    this.dialogLoading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.OrderBoxClose()
                        this.getListPage()
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }else if(this.boxType=='take') {     //收货
                const flag = itemList.some(item=>{
                    return item.receiveQuantity==0||item.receiveQuantity==''
                })
                if(flag){
                    this.$message.error('收货量不能为空且大于0')
                    this.dialogLoading=false
                    return
                }
                receive({
                    expectArrivalTime:this.addInfo.expectArrivalTime,
                    fileId:this.addInfo.fileId,
                    freight:this.addInfo.freight,
                    id:this.addInfo.id,
                    logisticsId:this.addInfo.logisticsId,
                    logisticsNo:this.addInfo.logisticsNo,
                    otherFee:this.addInfo.otherFee,
                    tips:this.addInfo.tips,
                    itemList:itemList}).then(res=>{
                    this.dialogLoading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.OrderBoxClose()
                        this.getListPage()
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }else if(this.boxType=='update'){   //编辑
                update(
                    {
                        ...this.addInfo,
                        itemList:itemList
                    }
                ).then(res=>{
                    this.dialogLoading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.OrderBoxClose()
                        this.getListPage()
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }else if(this.boxType=='fastPutStorage'){  //快捷入库
                quickPutInForReceiveNote({
                    itemList:itemList,
                    expectArrivalTime:this.addInfo.expectArrivalTime,
                    fileIdList:this.addInfo.fileIdList,
                    freight:this.addInfo.freight,
                    id:this.addInfo.id,
                    freightShareType:this.addInfo.freightShareType,
                    logisticsId:this.addInfo.logisticsId,
                    logisticsNo:this.addInfo.logisticsNo,
                    otherFee:this.addInfo.otherFee,
                    tips:this.addInfo.tips,
                }).then(res=>{
                    this.dialogLoading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.OrderBoxClose()
                        this.getListPage()
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }
        })
    },
    addProduct(){
        const arr = this.addGoodsList.map(item=>item.productId)
        this.selectedList.forEach(item=>{
            if(!arr.includes(item.productId)){
                this.addGoodsList.push(item)
            }
        })
        this.selectGoodsBox=false
        this.goodsListInfo.keyword=''
    },
    selectGoods(val,row){
        let arr = this.selectedList.map(item=>item.productId)
        if(!arr.includes(row.productId)){
            this.selectedList.push({
                ...row,
                tips:'',
                noticeQuantity:'',
                hadNoticeQuantity:row.hadNoticeNumber
            })
        }else{
            this.selectedList.splice(arr.indexOf(row.productId),1)
        }
    },
    selectAll(){
        const arr = this.selectedList.map(item=>item.productId)
        if(val.length!=0){
            val.forEach(item=>{
                if(!arr.includes(item.productId)){
                    this.selectedList.push({
                        ...item,
                        tips:'',
                        noticeQuantity:'',
                        hadNoticeQuantity:item.hadNoticeNumber
                    })
                }
            })
        }else{
            const arr = this.goodsList.map(item=>item.productId)
            arr.forEach(item=>{
                this.selectedList.forEach((i,index)=>{
                    if(i.productId==item.productId) this.selectedList.splice(index,1)
                })
            })
        }
    },
    selectStorage(info){
        this.$refs.addInfo.resetFields()
        this.purchaseList=[]
        this.addInfo.storageId=info.id
    },
    pushProduct(){
        this.selectedList=this.addGoodsList.map(item=>item)
        this.getPurchaseProductInfo()
    },
    goodsCurrentChange(val){
        this.goodsListInfo.current=val
        this.getPurchaseProductInfo()
    },
    getPurchaseProductInfo(info){
        if(info=='find') this.goodsListInfo.current=1
        this.$refs.addInfo.validate(valid=>{
            if(!valid) return
            this.dialogLoading=true
            let id = ''
            this.purchaseList.forEach(item=>{
                if(item.purchaseNo==this.addInfo.purchaseNo) id = item.id
            })
            getPurchaseProductInfo({
                current:this.goodsListInfo.current,
                pageSize:this.goodsListInfo.pageSize,
                keyword:this.goodsListInfo.keyword,
                purchaseId:id,
                isFilterZero:this.goodsListInfo.isFilterZero
            }).then(res=>{
                console.log(res);
                this.selectGoodsBox=true
                this.dialogLoading=false
                this.goodsList = res.data.values
                this.goodsListInfo.total=res.data.pageInfo.total
                const arr = this.selectedList.map(item=>{
                    return item.productId
                })
                this.goodsList.forEach((item,index)=>{
                    if(arr.includes(item.productId)){
                        this.$nextTick(()=>{
                            this.$refs.goodsTable.toggleRowSelection(this.goodsList[index]);
                        })
                    }
                })
            })
        })
    },
    getPurchaseNoList(val){
        this.dialogLoading=true
        getPurchaseNoList(this.addInfo.storageId).then(res=>{
            this.purchaseList=res.data.data
            this.dialogLoading=false
        })
    },
    getPurchase(info){
        console.log(info);
        this.addInfo.purchaseBy=info.purchaseBy
        this.addInfo.supplierId=info.supplierId
        this.addInfo.freightShareType=info.freightShareType
        this.addInfo.logisticsId=info.logisticsId
        this.addInfo.logisticsNo=info.logisticsNo
        this.mytoken.purchaseId=info.id
        if(this.addInfo!=''){
            this.required=true
        }else{
            this.required=false
        }
    },
    uploadSuccess(res,file,fileList){
        console.log(res,file,fileList);
        if(res.code==200){
            this.addInfo.fileIdList.push(res.data.fileId)
            this.fileList.push({name:file.name,fileId:res.data.fileId})
        }else{
            this.$message.error(res.message)
        }
    },
    uploadSuccessRes(res,file,fileList){
        console.log(res);
        if(this.batchUploadType=='商品'||this.batchUploadType=='入库量'||this.batchUploadType=='收货量'){
            if(res.code==200){
                if(this.addGoodsList.length==0){
                    this.addGoodsList=res.data
                    return
                }
                res.data.forEach(item=>{
                    this.addGoodsList.forEach((i,ind)=>{
                        if(item.productId==i.productId){
                            this.addGoodsList.splice(ind,1,item)
                        }
                    })
                    const arr = this.addGoodsList.map(item=>{
                        return item.productId
                    })
                    if(!arr.includes(item.productId)){
                        this.addGoodsList.push(item)
                    }
                })
                this.batchUploadClose()
                this.$message.success(res.message)
            }else{
                this.$message.error(res.message)
                this.faildList=res.data.map((item,index)=>{
                    return {
                        id:index,
                        value:item
                    }
                })
            }
        }else{
            if(res.code==200){
                this.batchUpload=false
                this.$message.success(res.message)
                this.getListPage()
            }else{
                this.$message.error(res.message)
                this.faildList=res.data.map((item,index)=>{
                    return {
                        id:index,
                        value:item
                    }
                })
            }
        }
    },
    handleRemove(file,ind){
        console.log(file);
        this.addInfo.fileIdList.forEach((item,index)=>{
            if(item==file.fileId){
                this.addInfo.fileIdList.splice(index,1)
            }
        })
        this.fileList.splice(ind,1)
        if(this.title=='添加收货单'||!file.id) return
        delFile(file.id).then(res=>{
            if(res.data.code==200) this.$message.success('附件已删除')
            if(res.data.code!=200) this.$message.error(res.data.message)
        })
    }, 
    select(val){
        this.selectList=val
    },
    addStart(info){
        this.title=info
        this.boxType='add'
        this.getAddInit()
    },
    getPageInit(){
        this.loading=true
        pageInit().then(res=>{
            this.loading=false
            console.log(res);
            this.checkStatus=res.data.data.checkStatus
            this.searchTime=res.data.data.searchTime
            this.searchTimeType=res.data.data.searchTimeType
            this.storageList=res.data.data.storageList
            this.statusList=res.data.data.statusList
            console.log(this.storageList);
            this.$set(this,'shopList',res.data.data.shopList)
        })
    },
    getID(shopId,marketPlaceId,name,currency){
        this.pageInfo.shopId=shopId
        this.pageInfo.marketPlaceId=marketPlaceId
        this.currency=currency
        this.getListPage()
    },
    getListPage(){
        this.loading=true
        listPage(this.pageInfo).then(res=>{
            this.loading=false
            this.tableData=res.data.values.map(item=>{
                var num = 0
                item.itemList.forEach(i=>{
                    num+=i.notCheckQuantity
                })
                item.noCheckNum=num
                return item
            })
            this.total=res.data.pageInfo.total
            this.getPageInit()
            const orderId=this.$route.query.order_id
            console.log(orderId);
            if(orderId) this.purchaseCreateReceiveInit(orderId)
        })
    },
    getAddInit(){
        this.loading=true
        addInit().then(res=>{
            this.loading=false
            console.log(res);
            this.addOrderBox=true
            this.$nextTick(()=>{
                this.$refs.addInfo.clearValidate()
            })
            this.freightShareTypeList=res.data.data.freightShareTypeList
            this.storageList=res.data.data.storageList
            this.supplier=res.data.data.supplier
            this.transferTypeList=res.data.data.transferTypeList

            // if(this.purchaseList.length!=0){
            //     this.detailPurchaseList=this.purchaseList.forEach(item=>{
            //         const arr = item.itemList.forEach
            //     })
            // }
        })
    },
    handleCurrentChange(val){
        this.pageInfo.current=val
        this.getListPage()
    },
    handleSizeChange(val){
        this.pageInfo.pageSize=val
        this.getListPage()
    },
    find(){
        this.pageInfo.current=1
        this.getListPage()
    }
}
}
</script>

<style scoped lang='scss'>
.order{
    width: 100%;
    height: calc(100% - 68px);
    display: flex;
    flex-direction: column;
}
::v-deep .el-input-group__prepend {
    background-color: #fff;
}
::v-deep .el-input-group__append {
    background-color: #fff;
}
// ::v-deep .el-drawer__header{
//     display: none;
// }

// 表格样式==================================================
::v-deep .outTable .cell .el-table__expand-icon{
    display: none;
}
::v-deep .outTable .el-table__row--level-1{
    .cell{
        >.el-checkbox{
            display: none;
        }
    }
}
::v-deep .outTable .el-table__row--level-0{
    background:#EBEEF5;
}
.tableMenu{
    display: flex;
    align-items: center;
    >div{
        margin-right:20px
    }
}
::v-deep .outTable .cell .el-table__indent{
    display: none;
}
::v-deep .outTable .cell .el-table__placeholder{
    display: none;
}
.option {
    >div{
        >span:nth-of-type(1){
            color: #999999;
        }
    }
}
.oneline{
    white-space: nowrap;  
    text-overflow:ellipsis;
    overflow:hidden;
}
//==============================================================

::v-deep .el-select-group__title{
    font-size:12px;
    margin-left: -20px;
    transform: scale(0.86);
}
</style>