import request from '@/utils/request.js'

export function pageInit(){
    return request({
        url:'/api/warehouseReceiveNote/pageInit',
        method:'get',
    })
}
export function listPage(params){
    return request({
        url:'/api/warehouseReceiveNote/listPage',
        method:'get',
        params
    })
}
export function addInit(){
    return request({
        url:'/api/warehouseReceiveNote/addInit',
        method:'get'
    })
}

export function getPurchaseNoList(id) {
    return request({
        url:'/api/warehouseReceiveNote/getPurchaseNoList',
        method:'get',
        params:{
            storageId:id
        }
    });
}

export function getPurchaseProductInfo(params) {
    return  request({
        url:'/api/warehouseReceiveNote/getPurchaseProductInfo',
        method:'get',
        params
    });
}

export function warehouseReceiveNote(data) {
    return request({
        url:'/api/warehouseReceiveNote',
        method:'post',
        data
    });
}

export function remove(id) {
    return request({
        url:`/api/warehouseReceiveNote/${id}`,
        method:'delete'
    });
}
// 批量删除
export function batchRemove(data) {
    return request({
        url:`/api/warehouseReceiveNote/batchDelete`,
        method:'put',
        data
    });
}
export function detail(id) {
    return request({
        url:`/api/warehouseReceiveNote/${id}`,
        method:'get'
    });
}
export function updateInit(id) {
    return request({
        url:`/api/warehouseReceiveNote/updateInit/${id}`,
        method:'get'
    });
}
export function receive(data) {
    return request({
        url:`/api/warehouseReceiveNote/receive`,
        method:'post',
        data
    });
}
export function update(data) {
    return request({
        url:`/api/warehouseReceiveNote`,
        method:'put',
        data
    });
}
export function qualityCheckInit(data) {
    return request({
        url:`/api/warehouseReceiveNote/qualityCheckInit`,
        method:'post',
        data
    });
}
export function batchQualityCheck(data) {
    return request({
        url:`/api/warehouseReceiveNote/batchQualityCheck`,
        method:'post',
        data
    });
}

export function receiveQuickPutIn(data) {
    return request({
      url:'/api/warehouseReceiveNote/receiveQuickPutIn',
      method:'post',
      data
    });
  }


export function getFile(id) {
    return request({
        url:`/api/file/upload/getFile/${id}`,
        method:'get',
        responseType: "blob"
    })
}

// 删除附件 
export function delFile(id) {
    return request({
        url:`/api/file/upload/fileMoreDelete/${id}`,
        method:'delete',
    })
}

export function quickPutInForReceiveNote(data) {
    return request({
        url:'/api/warehouseReceiveNote/quickPutInForReceiveNote',
        data,
        method:'post'
    });
}

// 详情关联入库单
export function getRelatedPutInNote(id) {
    return request({
        url:`/api/warehouseReceiveNote/getRelatedPutInNote/${id}`,
        method:'get',
    })
}

// 详情关联质检单
export function getRelatedQualityCheckNoteNo(id) {
    return request({
        url:`/api/warehouseReceiveNote/getRelatedQualityCheckNoteNo/${id}`,
        method:'get',
    })
}
// 操作记录
export function operationLog(params) {
    return request({
        url:`/api/warehouseOperateRecord/list`,
        method:'get',
        params
    })
}

export function purchaseCreateReceiveInit(data) {
    return request({
        url:'/api/warehouseReceiveNote/purchaseCreateReceiveInit',
        data,
        method:'post'
    });
}

